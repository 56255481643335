





































import { Core } from "@/store/core";
import {
    Component,
    Vue,
    Watch,
} from 'vue-property-decorator';
@Component({
    components: {},
    computed: {}
})

export default class Home extends Vue {

    private form: any = {
        "personal_id": null,
        "tel": null,
        "status": false
    }

    private async calling() {
        let user = await Core.postHttp(`/user/account/forgetpassword/`, this.form)
        if (user) {
            alert('ส่งคำขอไปที่ผู้ดูแลระบบแล้ว')
            this.$router.push('/')
        }
    }
}
